import React, { useState } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, TextField, ArrowBackSVGIcon } from 'react-md';
import { ProgressBar } from '../../';
import { getEmailAvailability } from '../../../redux/slices/users.slice';
import { useDebounce } from '../../../hooks';
import { isNotEmpty } from '../../../utils';

const validateEmail = email => {
  const emailRegex = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );
  return emailRegex.test(email);
};

export default function UserEmailAddress({
  data,
  editData,
  actions,
  nextStep,
  title,
  previousStep,
  currentStep
}) {
  const debouncedCheckEmail = useDebounce((nextValue) => checkEmail(nextValue), 500);
  const [email, setEmail] = useState(editData?.email || data?.email || '');
  const [inUse, setInUse] = useState(false);

  async function checkEmail(value) {
    const emailAvailable = await getEmailAvailability(value);
    setInUse(!emailAvailable && (data?.email !== value ? true : !isNotEmpty(data) ? true : false));
  }

  async function handleEmailChange(value) {
    actions.update({ email: value });
    setEmail(value);
    /* When typing in an email, once it is validated to be an email string,
    * upon change, perform the HTTP call to user-api to determine if the email
    * is available or already taken. */
    if (validateEmail(value)) {
      debouncedCheckEmail(value);

    } else {
      setInUse(false);
    }
  }

  function isDisabled() {
    return !validateEmail(email);
  };

  const errorMessage = () => {
    if (inUse) {
      return `The email "${email}" is already used. Please use a unique email.`;
    }
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={previousStep} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {isNotEmpty(data) ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={data ? 3 : 4} />
      <div className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">What’s their email address?</div>
          </div>
          <div>
            <TextField
              id="person-email-input"
              name="person-email-input"
              className="wizard-text-input"
              label="Email address"
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              theme="outline"
              error={inUse}
              message={errorMessage()}
            />
            <div className="wizard-error">
              {errorMessage()}
            </div>
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              disabled={isDisabled() || inUse}
              onClick={nextStep}
              theme="primary"
              themeType="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
