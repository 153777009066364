import { useState, useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, AppBar, AppBarNav, AppBarTitle, ArrowBackSVGIcon, useAddMessage } from 'react-md';
import Select from 'react-select';
import { ProgressBar, TagContainer } from '../../';
import { refreshUsers } from '../../../redux/slices/users.slice';
import { getOperators, getSuggestedOperators, postOperators, deleteOperators, getUserOperators } from '../../../redux/slices/operators.slice';
import { isNotEmpty } from '../../../utils';

const UserOperatorSelect = ({
  actions,
  operators,
  suggestedOperators,
  userOperators,
  data,
  editData,
  title,
  innerRef,
  previousStep,
  currentStep,
  nextStep,
  hideStepWizard
}) => {
  const addMessage = useAddMessage();
  const dispatch = useDispatch();
  const { update } = actions;
  const [options, setOptions] = useState([]);
  const [suggestedOptions, setSuggestedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [suggestedSelections, setSuggestedSelections] = useState([]);
  const [existingOptions, setExistingOptions] = useState([]);
  const dataExternalKey = data?.externalKey;
  const editDataExternalKey = data?.externalKey;

  useEffect(() => {
    const key = dataExternalKey ? dataExternalKey : editDataExternalKey ? editDataExternalKey : null;

    if (key) {
      dispatch(getSuggestedOperators(key));
    }

    dispatch(getOperators());
  }, [dataExternalKey, editDataExternalKey, dispatch]);

  useEffect(() => {
    if (dataExternalKey) {
      dispatch(getUserOperators(dataExternalKey));
    }
  }, [dataExternalKey, dispatch]);

  useEffect(() => {
    if (dataExternalKey) {
      setExistingOptions(formatExistingOptions(userOperators));
    }

    handleOptions(operators, setOptions);
    handleOptions(suggestedOperators, setSuggestedOptions);

    function handleOptions(operatorType, stateHandler) {
      const operatorData = dataExternalKey ? removeCommonOptions(userOperators, operatorType) : operatorType;
      const format = formatOptions(operatorData);

      stateHandler(format);
    }

  }, [dataExternalKey, operators, userOperators, suggestedOperators]);

  function formatOptions(arr) {
    return arr.map(operator => {
      return {
        label: `${operator.firstName} ${operator.lastName}`,
        value: operator.id
      };
    });
  }

  function formatExistingOptions(arr) {
    return arr.map(operator => {
      return {
        value: operator.id,
        label: `${operator.firstName} ${operator.lastName}`,
        status: 'existing',
        mapped: true
      };
    });
  }

  function removeCommonOptions(optionsArray, operatorArray) {
    let commonOptions = operatorArray;

    optionsArray.forEach(operator => {
      commonOptions = [...commonOptions.filter(element => operator.id !== element.id)];
    });

    return commonOptions;
  }

  const handleExisting = (value) => {
    if (value.mapped && value.status === 'remove') {
      const removeSelection = options.filter(option => option.value !== value.value);
      value.status = 'existing';

      return setOptions([...removeSelection]);
    }

    setOptions([value, ...options]);

    if (suggestedOperators.find(({ id }) => value.value === id)) {
      setSuggestedOptions([value, ...suggestedOptions]);
    }

    if (value.mapped) {
      return value.status = 'remove';
    }

    const removeSelection = existingOptions.filter(option => option.value !== value.value);
    setExistingOptions(removeSelection);
  };

  const handleSuggestedSelect = (value, action) => {
    if (action.action === 'select-option') {
      const removeSelection = options.filter(option => option.value !== action.option.value);
      setOptions(removeSelection);

      if (isNotEmpty(data)) {
        const removeCommonValue = suggestedOptions.filter(option => option.value !== action.option.value);
        setSuggestedOptions(removeCommonValue);

        if (userOperators.find(({ id }) => id === action.option.value)) {
          const updateStatus = existingOptions.map(operator => {
            if (operator.value === action.option.value) {
              operator.status = 'existing';
            }

            return operator;
          });

          return setExistingOptions(updateStatus);
        }

        value[0].status = 'add';

        return setExistingOptions([...value, ...existingOptions]);
      }

      setSuggestedSelections([...value]);
    }

    if (action.action === 'remove-value') {
      const commonOptions = suggestedSelections.filter(selection => selection.value !== action.removedValue.value);

      setSuggestedSelections(commonOptions);
      setOptions([action.removedValue, ...options]);
    }

    if (action.action === 'clear') {
      setOptions([...suggestedSelections, ...options]);
      setSuggestedSelections([]);
    }
  };

  const handleOperatorsSelect = (value, action) => {
    if (action.action === 'select-option') {
      const removeSelection = suggestedOptions.filter(selection => selection.value !== action.option.value);

      setSuggestedOptions(removeSelection);

      if (isNotEmpty(data)) {
        const removeCommonValue = options.filter(option => option.value !== action.option.value);
        setOptions(removeCommonValue);

        if (userOperators.find(({ id }) => id === action.option.value)) {
          const updateStatus = existingOptions.map(operator => {
            if (operator.value === action.option.value) {
              operator.status = 'existing';
            }

            return operator;
          });

          return setExistingOptions(updateStatus);
        }

        value[0].status = 'add';

        return setExistingOptions([...value, ...existingOptions]);
      }

      setSelectedOptions([...value]);
    }

    if (action.action === 'remove-value') {
      const commonOptions = suggestedOperators.find(({ id }) => id === action.removedValue.value);

      if (commonOptions) {
        setSuggestedOptions([action.removedValue, ...suggestedOptions]);
      }

      const removeSelection = selectedOptions.filter(selection => selection.value !== action.removedValue.value);
      setSelectedOptions(removeSelection);
    }

    if (action.action === 'clear') {
      const commonOptions = [];

      selectedOptions.forEach(operator => {
        if (suggestedOperators.find(({ id }) => id === operator.value)) {
          commonOptions.push(operator);
        }
      });

      if (commonOptions) {
        setSuggestedOptions([...commonOptions, ...suggestedOptions]);
      }

      setSelectedOptions([]);
    }
  };

  function updateOperators() {
    let addOperators = [];
    let removeOperators = [];

    existingOptions.forEach(({ status, value }) => {
      if (status === 'add') {
        addOperators.push(value);
      }

      if (status === 'remove') {
        removeOperators.push(value);
      }
    });

    if (isNotEmpty(addOperators)) {
      dispatch(postOperators(editData.externalKey, addOperators, addMessage));
    }

    if (isNotEmpty(removeOperators)) {
      dispatch(deleteOperators(editData.externalKey, removeOperators, addMessage));
    }
  }

  const handleNext = () => {
    if (!data) {
      update({
        operators: [...suggestedSelections, ...selectedOptions].map(operator => operator.value),
        removeOperators: []
      });

      nextStep();
    };

    if (data) {
      updateOperators();
      hideStepWizard();
      dispatch(refreshUsers());
    };
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={previousStep} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {isNotEmpty(data) ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={data ? 3 : 4} />
      <div ref={innerRef} className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">Which Operators should be assigned?</div>
            <div className="wizard-step-subheader">
              These assignments will dictate the list of Portal accounts a user can access via single sign on.
              <b> Only select an operator(s) if this user should access the respective Portal account.</b>
            </div>
          </div>
          {isNotEmpty(data) && (
            <div>
              <TagContainer
                defaultValue="No operators currently mapped to this user."
                tags={existingOptions}
                handleExisting={handleExisting}
              />
              {editData?.updatedRelationships && (
                <p className="operator-alert">
                  Please review and update (if necessary) this user’s Operator associations since changes were made to this user’s Organization associations
                </p>
              )}
              <p className="select-alert">Add new operators from either dropdown below</p>
            </div>
          )}
          <div className="wizard-select-container">
            <Select
              isMulti
              classNamePrefix="wizard-select"
              closeMenuOnSelect={false}
              id="suggested-operator-select"
              className="wizard-select"
              placeholder="Suggested Operators"
              defaultValue={suggestedSelections}
              value={suggestedSelections}
              onChange={handleSuggestedSelect}
              options={suggestedOptions}
            />
            <Select
              isMulti
              isClearable
              closeMenuOnSelect={false}
              classNamePrefix="wizard-select"
              id="operator-select"
              className="wizard-select"
              placeholder="Operators"
              defaultValue={selectedOptions}
              value={selectedOptions}
              options={options}
              onChange={handleOperatorsSelect}
            />
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              onClick={handleNext}
              theme="primary"
              themeType="contained"
            >
              {isNotEmpty(data) ? 'confirm edits' : 'continue'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef((props, ref) => <UserOperatorSelect innerRef={ref} {...props} />);
