import { FontIcon, DropdownMenu, MenuItem, BELOW_INNER_RIGHT_ANCHOR } from 'react-md';

export default function PeopleTableMenu({ actions, title, index, item, resetSort }) {
  const inactive = new Date(item.deactivation) < new Date();

  return (
    <DropdownMenu
      id={`table-menu-button-${index}`}
      className="table-menu-button"
      buttonType="icon"
      anchor={BELOW_INNER_RIGHT_ANCHOR}
      buttonChildren={<FontIcon>more_vert</FontIcon>}
    >
      {inactive ?
        <MenuItem
          key={1}
          id={`people-menu-reactivate-${index}`}
          children="Reactivate"
          leftAddon={<FontIcon>person_add</FontIcon>}
          onClick={() => actions.showReactivateDialog({ data: item, title, action: actions.reactivateUser })}
        />
        :
        <>
          <MenuItem
            key={1}
            id={`people-menu-send-email-${index}`}
            disabled={item.relationships === null}
            children="Send Email"
            leftAddon={<FontIcon>send</FontIcon>}
            onClick={() => actions.showStepWizard({ data: { ...item, email: true }, title: 'sendEmail', edit: true })}
          />
          <MenuItem
            key={2}
            id={`people-menu-edit-${index}`}
            children="Edit"
            leftAddon={<FontIcon>edit</FontIcon>}
            onClick={
              () => {
                resetSort();
                actions.showStepWizard({ data: item, title, edit: true });
              }
            }
          />
          <MenuItem
            key={3}
            id={`people-menu-deactivate-${index}`}
            children="Deactivate"
            leftAddon={<FontIcon>block</FontIcon>}
            onClick={() => actions.showDeactivateDialog({ data: item, title, action: actions.deactivateUser })}
          />
        </>
      }
    </DropdownMenu>
  );
}
