import { createRoot } from 'react-dom/client';
import { Configuration, MessageQueue } from 'react-md';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { App } from './components';
import store from './redux/store';
// import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import './index.scss';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_CORE_USER_API_URL}/graphql`,
  credentials: 'include'
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Configuration>
        <BrowserRouter>
          <MessageQueue id="app-snackbar">
            <App />
          </MessageQueue>
        </BrowserRouter>
      </Configuration>
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
