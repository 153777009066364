import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';
import _ from 'lodash';
import { checkPermissions } from './rbac';

const cookies = new Cookies();

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

export const idFormatter = (string) => string.toLowerCase().split(' ').join('-');

export function createFilterList(data, labelName = 'name', valueName = 'id') {
  return _.uniqBy(data.map((item) => ({ name: item[labelName], id: item[valueName], isChecked: true })), 'name');
}

export function TreeConstructor(unstructured) {
  let finalForm = {};
  let leaf = '';
  if (unstructured.name && unstructured.children && unstructured.children.length > 0) {
    finalForm[unstructured.name] = unstructured.children.map(child => {
      return TreeConstructor(child);
    });
  } else if (unstructured.name) {
    leaf = unstructured.name;
  }
  return finalForm[unstructured.name] ? finalForm : leaf;
};

export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

export function redirectToSignIn() {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
}

export function getCookie(name) {
  return cookies.get(name);
}

export function deleteCookies() {
  cookies.remove('86553b39', {
    domain: '.parkhub.com'
  });
  cookies.remove('accessToken', {
    domain: '.parkhub.com'
  });
  cookies.remove('_gtmUID', {
    domain: '.parkhub.com'
  });
  cookies.remove('perm', {
    domain: '.parkhub.com'
  });
}

export function refreshSession() {
  const value = getCookie('86553b39');

  if (value && value !== 'undefined') {
    return ajax.post('authenticate?refreshCookie=true');

  } else {
    console.error(`Can not refresh session because current cookie expired`);
  }
}

export function getAuthUser() {
  if (getCookie('86553b39')) {
    return jwtDecode(getCookie('86553b39'))?.payload;
  } else { return null; }
}

export function getPermissions() {
  if (getCookie('perm')) {
    return jwtDecode(getCookie('perm'))?.payload;
  } else { return {}; }
}

export function getSessionTime() {
  if (getCookie('86553b39')) {
    const iat = jwtDecode(getCookie('86553b39'))?.iat;
    return Math.abs((new Date(iat * 1000) - new Date()) / 1000 / 60);
  }
}

export const isGlobalAdmin = getAuthUser()?.g?.map(o => o?.r)?.includes('GLOBAL_ADMIN');

export function flatten() {
  const flat = [];

  for (let i = 0; i < arguments.length; i++) {
    if (arguments[i] instanceof Array) {
      flat.push(...this.flatten(...arguments[i]));
    } else if (arguments[i] instanceof Object) {
      flat.push(...this.flatten(...Object.values(arguments[i])));
    } else {
      flat.push(arguments[i]);
    }
  }

  return flat;
}

function checkForEnvMatch() {
  if (getCookie('86553b39')) {
    return getAuthUser()?.ne === process.env.REACT_APP_ENV_TAG;

  } else {
    return false;
  }
}

export const checkAuth = () => {
  const authCookie = getCookie('86553b39');
  const hasAppAccess = checkPermissions('users');

  if (authCookie && checkForEnvMatch() && hasAppAccess) {
    return true;
  } else {
    return false;
  }
};

export * from './fuseOptions';
export * from './rbac';
