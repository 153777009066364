import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { WizardDialog, DeactivateModal } from '../';
import OrganizationsTable from './OrganizationsTable';
import { getOrganizations, deactivateOrg } from '../../redux/slices/organizations.slice';
import { clearAllFilters } from '../../redux/slices/filter.slice';

const organizationsListLabelKeys = [
  { label: 'Child Organization', key: 'childName', sortable: true },
  { label: 'Relationship', key: 'relationshipType', sortable: true },
  { label: 'Parent Organization', key: 'parentName', sortable: true },
  { label: 'Landmark', key: 'landmarkName', sortable: true }
];

export default function Organizations({ actions }) {
  const dispatch = useDispatch();

  const wizardVisible = useSelector(state => state.wizard.visible);
  const wizardContent = useSelector(state => state.wizard.data);
  const wizardTitle = useSelector(state => state.wizard.title);
  const relationships = useSelector(state => state.organizations.relationships);
  const filteredData = useSelector(state => state.filter.orgFilteredData);
  const isLoading = useSelector(state => state.organizations.isLoading);

  const {
    hideStepWizard,
    hideDeactivateDialog
  } = actions;

  useEffect(() => {
    dispatch(clearAllFilters());
    dispatch(getOrganizations());

    return () => {
      hideStepWizard();
    };
  }, [hideStepWizard, dispatch]);

  const orgActions = {
    ...actions,
    deactivateOrg
  };

  return (
    <>
      <div>
        <OrganizationsTable
          title="Organizations"
          labelKeys={organizationsListLabelKeys}
          data={!!filteredData ? filteredData : relationships}
          actions={orgActions}
          isLoading={isLoading}
        />
      </div>
      <WizardDialog
        visible={wizardVisible}
        onHide={hideStepWizard}
        data={wizardContent}
        title={wizardTitle}
      />
      <DeactivateModal onHide={hideDeactivateDialog} />
      <LoadingSpinner
        show={isLoading}
        delay={650}
      />
    </>
  );
}
