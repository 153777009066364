import { FontIcon, DropdownMenu, MenuItem, BELOW_INNER_RIGHT_ANCHOR } from 'react-md';

export default function LotsGaragesTableMenu({ actions, title, index, item, resetSort }) {
  const inactive = new Date(item.deactivation) < new Date();

  return (
    <DropdownMenu
      id={`table-menu-button-${index}`}
      className="table-menu-button"
      buttonType="icon"
      anchor={BELOW_INNER_RIGHT_ANCHOR}
      buttonChildren={<FontIcon>more_vert</FontIcon>}
    >
      {inactive ?
        <MenuItem
          key={1}
          id={`people-menu-reactivate-${index}`}
          children="Reactivate"
          leftAddon={<FontIcon>person_add</FontIcon>}
          onClick={() => actions.showReactivateDialog({ data: item, title, action: actions.reactivateUser })}
        />
        :
        <MenuItem
          key={1}
          id={`lot-menu-edit-${index}`}
          primaryText="Edit"
          leftAddon={<FontIcon>edit</FontIcon>}
          onClick={() => {
            resetSort();
            actions.showStepWizard({ data: item, title, edit: true });
          }}
        />
      }
    </DropdownMenu>
  );
}
