import { configureStore } from '@reduxjs/toolkit';
import operators from './slices/operators.slice';
import tree from './slices/tree.slice';
import session from './slices/session.slice';
import deactivateDialog from './slices/deactivateDialog.slice';
import reactivateDialog from './slices/reactivateDialog.slice';
import errorDialog from './slices/errorDialog.slice';
import wizard from './slices/wizard.slice';
import filter from './slices/filter.slice';
import organizations from './slices/organizations.slice';
import users from './slices/users.slice';
import lots from './slices/lots.slice';

const store = configureStore({
  reducer: {
    wizard,
    session,
    users,
    organizations,
    filter,
    operators,
    lots,
    deactivateDialog,
    reactivateDialog,
    tree,
    errorDialog
  },
  devTools: process.env.REACT_APP_ENV_TAG !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
});

export default store;
