import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddMessage } from 'react-md';
import StepWizard from 'react-step-wizard';
import _ from 'lodash';
import {
  LotWizardStep,
  OrgName,
  OrgRelationshipTypes,
  LandmarkStep,
  UserFullName,
  UserEmailAddress,
  UserRoleSelect,
  UserOperatorSelect,
  UserReviewAndConfirm
} from '../';
import { createOrganization, createOrganizationRelations, updateOrganizationRelationships } from '../../redux/slices/organizations.slice';
import { updateEditData } from '../../redux/slices/wizard.slice';
import { clearSearchTerm } from '../../redux/slices/filter.slice';
import { PARENT_KEY } from '../../constants/orgKeys';
import './Wizard.scss';

function Wizard({
  title,
  hideStepWizard,
  organizations
}) {
  const addMessage = useAddMessage();
  const wizardRef = useRef();
  const dispatch = useDispatch();
  const wizardData = useSelector(state => state.wizard.data);
  const wizardEditData = useSelector(state => state.wizard.editData);
  const relationships = useSelector(state => state.organizations.relationships);
  const operators = useSelector(state => state.operators.allOperators);
  const suggestedOperators = useSelector(state => state.operators.suggestedOperators);
  const userOperators = useSelector(state => state.operators.userOperators);
  const orgList = useSelector(state => state.organizations.data);
  const peopleError = useSelector(state => state.users.isError);
  const user = useSelector(state => state.users.user);
  const allowNextStep = useSelector(state => state.wizard.allowNextStep);

  const update = (updateData) => {
    dispatch(updateEditData({ ...wizardData, ...wizardEditData, ...updateData }));
  };

  const confirmOrg = (returningOrg, newOrgData, returningOrgData) => {
    try {
      dispatch(clearSearchTerm());

      if (returningOrg) {
        dispatch(updateOrganizationRelationships(returningOrgData, addMessage));

      } else if (newOrgData.autoCompleteKey) {
        dispatch(createOrganizationRelations(newOrgData, addMessage));

      } else {
        dispatch(createOrganization(newOrgData, addMessage));
      }

    } catch (error) {
      console.error(error.message);
    }
  };

  const scrollToTop = () => {
    wizardRef.current.scrollIntoView();
  };

  const wizardActions = {
    update,
    confirmOrg
  };

  const titleToDialogTitle = {
    'People': 'User',
    'Organizations': 'Organization',
    'Lots/Garages': 'Lot/Garage'
  };

  const viewerOption = () => {
    const arr = relationships.map(x => ({ value: x.childKey, label: x.childName }));
    arr.splice(0, 0, { value: PARENT_KEY, label: 'ParkHub' });
    return _.uniqBy(arr, 'value');
  };

  const options = {
    roleOptions: [{ value: 'OPERATOR', label: 'Operator' }, { value: 'VIEWER', label: 'Viewer' }, { value: 'API_USER', label: 'API User' }],
    relationshipOptions: [
      { value: 'CLIENT', label: 'client' },
      { value: 'IN_HOUSE_STAFF', label: 'in house staff ' },
      { value: 'CITY_IN_HOUSE_STAFF', label: 'city in house staff ' },
      { value: 'CONTRACTED_STAFF', label: 'contracted staff' }
    ],
    parkhubOptions: [{ value: 'CLIENT', label: 'client' }],
    orgOptions: viewerOption(),
    userOrgOptions: {
      'OPERATOR': _.uniqBy(relationships.map((x) => ({ value: x.childKey, label: x.childName, type: x.relationshipType }))
        .filter(x => x.type === 'CITY_IN_HOUSE_STAFF' ||
          x.type === 'CITY_CONTRACTED_STAFF' ||
          x.type === 'IN_HOUSE_STAFF' ||
          x.type === 'CONTRACTED_STAFF'
        ), 'value'),
      'VIEWER': viewerOption(),
      'API_USER': viewerOption() // same as viewer
    },
    lotOperatorOptions: _.uniqBy(relationships.map((x) => ({ value: x.childKey, label: x.childName, type: x.relationshipType }))
      .filter(x => x.type === 'CITY_IN_HOUSE_STAFF' ||
        x.type === 'CITY_CONTRACTED_STAFF' ||
        x.type === 'IN_HOUSE_STAFF' ||
        x.type === 'CONTRACTED_STAFF'
      ), 'value')
  };
  const wizardSelect = {
    'People': [
      <UserFullName
        key={0}
        hideStepWizard={hideStepWizard}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        editData={wizardEditData}
        relationships={relationships}
      />,
      <UserEmailAddress
        key={1}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        editData={wizardEditData}
      />,
      <UserRoleSelect
        key={2}
        ref={wizardRef}
        options={options}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        user={user}
        editData={wizardEditData}
        err={peopleError}
        allowNextStep={allowNextStep}
      />,
      <UserOperatorSelect
        key={3}
        ref={wizardRef}
        actions={wizardActions}
        title={titleToDialogTitle[title]}
        data={wizardData}
        hideStepWizard={hideStepWizard}
        editData={wizardEditData}
        operators={operators}
        suggestedOperators={suggestedOperators}
        err={peopleError}
        userOperators={userOperators}
      />,
      <UserReviewAndConfirm
        key={4}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        editData={wizardEditData}
        hideStepWizard={hideStepWizard}
        err={peopleError}
        scrollToTop={scrollToTop}
      />
    ],
    'Organizations': [
      <OrgName
        key={0}
        options={options}
        relationships={relationships}
        orgList={orgList.filter(x => x.externalKey !== PARENT_KEY)}
        hideStepWizard={hideStepWizard}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
      />,
      <OrgRelationshipTypes
        key={1}
        options={options}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        editData={wizardEditData}
        hideStepWizard={hideStepWizard}
      />,
      <LandmarkStep
        key={2}
        options={options}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        editData={wizardEditData}
        hideStepWizard={hideStepWizard}
        orgList={orgList}
        organizations={organizations}
      />
    ],
    'Lots/Garages': [
      <LotWizardStep
        key={0}
        options={options}
        title={titleToDialogTitle[title]}
        actions={wizardActions}
        data={wizardData}
        hideStepWizard={hideStepWizard}
        editData={wizardEditData}
        relationships={relationships}
      />
    ],
    'sendEmail': [
      <UserReviewAndConfirm
        key={3}
        options={options}
        title={titleToDialogTitle['People']}
        actions={wizardActions}
        data={wizardData}
        editData={wizardEditData}
        hideStepWizard={hideStepWizard}
        err={peopleError}
        scrollToTop={scrollToTop}
      />
    ]
  };

  return (
    <StepWizard isLazyMount id="wizard" children={wizardSelect[title]} />
  );
};

export default Wizard;
