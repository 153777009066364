import { useState, useEffect } from 'react';
import { AppBar, AppBarNav, AppBarTitle, Button, ArrowBackSVGIcon, useAddMessage } from 'react-md';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import { ProgressBar } from '../../';
import { isNotEmpty } from '../../../utils';
import { getOrganizations } from '../../../redux/slices/organizations.slice';
import { updateLot } from '../../../redux/slices/lots.slice';
import { clearSearchTerm } from '../../../redux/slices/filter.slice';

export default function LotWizardStep({
  data,
  editData,
  hideStepWizard,
  relationships: relationshipsProp,
  currentStep,
  actions: { update },
  title,
  options
}) {
  const dispatch = useDispatch();
  const addMessage = useAddMessage();
  const [initialRelationships, setInitialRelationships] = useState(null);
  const [relationships, setRelationships] = useState(null);

  useEffect(() => {
    if (relationshipsProp.length < 1) {
      dispatch(getOrganizations());
    }

    const orgs = data && !!data[0].organizations && data[0].organizations.map((x) => {
      return {
        value: x.partyKey,
        label: x.displayName,
        isFixed: x.relationshipTypes.includes('PARTNER_LOCATION') || x.relationshipTypes.includes('INTEGRATED_LOCATION')
      };
    });

    if (isNotEmpty(data)) {
      setInitialRelationships(orgs);
      setRelationships(orgs);
    }
  }, [data, dispatch, relationshipsProp]);

  const handleOrgChange = (value, { action, removedValue }) => {
    let val = value;

    const formatData = (list) => list.map((x) => {
      return {
        externalKey: x.value,
        displayName: x.label
      };
    });

    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;

      case 'clear':
        val = relationships.filter(v => v.isFixed);
        break;

      default:
        break;
    }

    setRelationships(val);

    update({
      relationships: val?.length ? formatData(val) : null
    });
  };

  const confirmLot = (obj) => {
    dispatch(clearSearchTerm());
    dispatch(updateLot(obj, addMessage));
  };

  const handleSubmit = () => {
    data.map((v, i) => {
      const edits = {
        displayName: editData[i].displayName || [],
        externalKey: editData[i].externalKey || [],
        organizations: editData.relationships || []
      };

      return confirmLot({ original: v, edits });
    });

    hideStepWizard();
  };

  function disableConfirmEdit() {
    // initialRelationships and relationships go back and forth between the null and empty array depending on how they were removed
    // no relationships and no change
    if (initialRelationships === null && relationships === null) {
      return true;
    }
    // no initial relationships, user added but then removed individually
    if (initialRelationships && initialRelationships.length === 0 && relationships && relationships.length === 0) {
      return true;
    }
    // no initial relationships, user added and then used clear function
    if (initialRelationships && initialRelationships.length === 0 && relationships === null) {
      return true;
    }

    if (!initialRelationships && relationships) {
      return false;
    }

    if (!initialRelationships || !relationships) {
      return false;
    }

    const initialValues = initialRelationships.map(arr => arr.value);
    const updatedValues = relationships.map(arr => arr.value);

    return _.isEqual(initialValues.sort(), updatedValues.sort());
  }

  const displayName = data && data.map(v => v.displayName);
  const multipleDisplayNames = displayName && displayName.length > 1;
  const displayNameFormatted = multipleDisplayNames ? displayName.join(', ') : displayName;
  const companySubheader = multipleDisplayNames ? 'These are the companies who manage' : 'This is the company who manages';
  const startedOrphaned = data && !data[0].organizations.length;

  const warningMessage = () => {
    if (relationships) {
      if (relationships.length === 1 && !startedOrphaned) {
        return `If you remove this relationship, you will orphan ${multipleDisplayNames ? 'lots' : 'lot'}: ${displayNameFormatted}`;
      } else if (relationships.length === 0 && !startedOrphaned) {
        return `You are about to remove all relationships to ${multipleDisplayNames ? 'lots' : 'lot'}: ${displayNameFormatted}`;
      }
    }
  };

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
  };

  return (
    <>
      <AppBar fixed theme="primary">
        <AppBarNav onClick={hideStepWizard} aria-label="Navigation">
          <ArrowBackSVGIcon />
        </AppBarNav>
        <AppBarTitle
          className="wizard-app-bar-title"
        >
          {isNotEmpty(data) ? `Edit ${title.toLowerCase()}` : `Creating a new ${title.toLowerCase()}`}
        </AppBarTitle>
      </AppBar>
      <ProgressBar step={currentStep} steps={1} />
      <div className="wrapper">
        <div className="wizard-step">
          <div className="wizard-step-header-container">
            <div className="wizard-step-header">
              {`What organization operates ${multipleDisplayNames ? `these ${displayName.length} lots or garages` : 'this lot or garage'}?`}
            </div>
            <div className="wizard-step-subheader">{`${companySubheader} the parking operations for: ${displayNameFormatted}`}</div>
          </div>
          <div>
            <Select
              classNamePrefix="wizard-select"
              isMulti
              backspaceRemovesValue={false}
              isClearable={relationships?.some(v => !v.isFixed)}
              id="lot-organization-select"
              className="wizard-select"
              placeholder="Organization"
              value={relationships}
              options={options.lotOperatorOptions}
              onChange={handleOrgChange}
              styles={styles}
            />
          </div>
          <div className="wizard-error">
            {warningMessage()}
          </div>
          <div>
            <Button
              className="wizard-button no-elevate"
              disabled={disableConfirmEdit()}
              onClick={handleSubmit}
              theme="primary"
              themeType="contained"
            >
              Confirm Edits
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
