import { createSlice } from '@reduxjs/toolkit';
import { create } from 'axios';
import { applyOrgFilter } from '../slices/filter.slice';
import { errorDialogToggle } from '../slices/errorDialog.slice';
import { setClientFilter, setTeamFilter } from '../slices/filter.slice';
import { createFilterList } from '../../utils';
import _ from 'lodash';

const ajax = create({
  contentType: 'application/json',
  baseURL: process.env.REACT_APP_CORE_USER_API_URL
});

ajax.defaults.withCredentials = true;

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    data: [],
    relationships: [],
    landmarks: [],
    isLoading: false,
    isError: false
  },
  reducers: {
    getOrganizationsPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    getOrganizationsRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    getOrganizationsSuccess: (state, action) => {
      state.data = action.payload.data;
      state.relationships = action.payload.relationships;
      state.isLoading = false;
      state.isError = null;
    },
    getLandmarksPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    getLandmarksRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    getLandmarksSuccess: (state, action) => {
      state.landmarks = action.payload;
      state.isLoading = false;
      state.isError = null;
    },
    createOrganizationPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    createOrganizationRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    createOrganizationSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    updateOrganizationPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    updateOrganizationRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    updateOrganizationSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    deleteOrganizationPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    deleteOrganizationRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    deleteOrganizationSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    updateOrganizationNamePending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    updateOrganizationNameRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    updateOrganizationNameSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    updateOrganizationRelationshipsPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    updateOrganizationRelationshipsRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    updateOrganizationRelationshipsSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    },
    getSpecifiedRelationshipsPending: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    getSpecifiedRelationshipsRejected: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    getSpecifiedRelationshipsSuccess: (state) => {
      state.isLoading = false;
      state.isError = null;
    }
  }
});

export default organizationsSlice.reducer;

export const {
  getOrganizationsPending,
  getOrganizationsRejected,
  getOrganizationsSuccess,
  getLandmarksPending,
  getLandmarksRejected,
  getLandmarksSuccess,
  createOrganizationPending,
  createOrganizationRejected,
  createOrganizationSuccess,
  updateOrganizationPending,
  updateOrganizationRejected,
  updateOrganizationSuccess,
  deleteOrganizationPending,
  deleteOrganizationRejected,
  deleteOrganizationSuccess,
  updateOrganizationNamePending,
  updateOrganizationNameRejected,
  updateOrganizationNameSuccess,
  updateOrganizationRelationshipsPending,
  updateOrganizationRelationshipsRejected,
  updateOrganizationRelationshipsSuccess,
  getSpecifiedRelationshipsPending,
  getSpecifiedRelationshipsRejected,
  getSpecifiedRelationshipsSuccess
} = organizationsSlice.actions;

export const getOrganizations = () => dispatch => {
  dispatch(getOrganizationsPending());

  ajax.get('/organizations?attachRelationships=true&activeOnly=true')
    .then(resp => {
      const relationships = _.flatten(resp.data.organizations.map(org => {
        if (!org.relationships) {
          org.relationships = [];
        }

        return org.relationships.map(rel => {
          return {
            parentName: org.displayName,
            parentKey: org.externalKey,
            childName: rel.displayName,
            childKey: rel.externalKey,
            childFrom: org.externalKey,
            relationshipType: rel.relationshipType,
            partyType: rel.partyType,
            relationshipKey: rel.relationshipKey,
            landmarkName: rel.landmarkName || 'N/A',
            hierarchy: rel.hierarchy
          };
        }).filter(r => {
          return (
            r.partyType !== 'PERSON' &&
            r.relationshipType !== 'CONTRACTED_CLIENT' &&
            typeof r.childKey !== 'undefined' &&
            r.hierarchy !== 'parent'
          );
        });
      }));

      const clients = _.uniqBy(
        relationships.filter(rel => {
          return rel.relationshipType === 'CLIENT' || rel.relationshipType === 'CONTRACTED_CLIENT' || rel.relationshipType === 'CONTRACTOR';
        }), 'childKey');

      const teams = _.uniqBy(
        relationships.filter(rel => {
          return rel.relationshipType === 'IN_HOUSE_STAFF' || rel.relationshipType === 'CONTRACTED_STAFF' || rel.relationshipType === 'CITY_IN_HOUSE_STAFF';
        }),
        'childKey'
      );

      const payload = {
        data: resp.data.organizations,
        relationships
      };

      dispatch(getOrganizationsSuccess(payload));
      dispatch(setClientFilter(createFilterList(clients, 'childName', 'childKey')));
      dispatch(setTeamFilter(createFilterList(teams, 'childName', 'childKey')));
      dispatch(applyOrgFilter(null));
    })
    .catch(error => {
      console.log('error: ', error);
      dispatch(getOrganizationsRejected(error));
    });
};

export const getLandmarks = () => dispatch => {
  dispatch(getLandmarksPending());

  ajax.get('/organizations/landmarks')
    .then(data => {
      dispatch(getLandmarksSuccess(data.data.landmarks));
    }).catch(error => {
      console.log('error: ', error);
      dispatch(getLandmarksRejected(error));
    });
};

export const createOrganization = (newOrg, addMessage) => async dispatch => {
  try {
    dispatch(createOrganizationPending());

    const postOrg = {
      displayName: newOrg.displayName,
      relationships: newOrg.multiOrgs.map(x => (
        {
          fromPartyKey: x.organization.value,
          relationshipType: x.relationshipType.value
        }
      ))
    };

    const successToast = `${newOrg.displayName} has been created`;

    const org = await ajax.post('/organizations', postOrg);

    if (newOrg.landmarkId) {
      await ajax.post('/organizations/landmarks', {
        associations: [
          {
            venueTeamKey: org.data.partyKey,
            landmarkId: newOrg.landmarkId
          }
        ]
      });
    }

    await dispatch(applyOrgFilter(null));
    await dispatch(getOrganizations());
    await dispatch(createOrganizationSuccess());

    addMessage({ children: successToast, action: 'dismiss' });

  } catch (error) {
    dispatch(createOrganizationRejected(error));
    dispatch(errorDialogToggle({ error, visible: true }));
  }
};

export const createOrganizationRelations = (editOrg, addMessage) => dispatch => {
  dispatch(updateOrganizationPending());

  if (editOrg.autoCompleteKey) {
    const autoCompleteRelBody = {
      relationships: editOrg.multiOrgs.map(x => ({
        toPartyKey: editOrg.autoCompleteKey,
        fromPartyKey: x.organization.value,
        relationshipType: x.relationshipType.value
      }))
    };

    const successToast = `New relationships for ${editOrg.displayName} have been created`;

    ajax
      .post('/relationships', autoCompleteRelBody)
      .then(() => {
        dispatch(applyOrgFilter(null));
        dispatch(getOrganizations());
      })
      .then(() => dispatch(createOrganizationSuccess()))
      .then(() => addMessage({ children: successToast, action: 'dismiss' }))
      .catch(error => {
        console.log('error: ', error);
        dispatch(updateOrganizationRejected(error));
        dispatch(errorDialogToggle({ error, visible: true }));
      });
  }
};

export const deactivateOrg = (org, addMessage) => dispatch => {
  dispatch(deleteOrganizationPending());

  const delBody = {
    relationships: [{
      fromPartyKey: org.parentKey,
      toPartyKey: org.childKey,
      relationshipType: org.relationshipType
    }]
  };

  const successToast = `${org.childName}'s relationship to ${org.parentName} has been deactivated`;

  ajax.delete('/relationships', { data: delBody })
    .then(() => {
      dispatch(deleteOrganizationSuccess());
      dispatch(getOrganizations());
      addMessage({ children: successToast, action: 'dismiss' });
    })
    .catch(error => {
      console.log('error: ', error);
      dispatch(deleteOrganizationRejected(error));
      dispatch(errorDialogToggle({ error, visible: true }));
    });
};

export const updateOrganizationName = orgData => {
  const { childOrgKey: externalKey, newChildOrgName: displayName } = orgData;
  return ajax.put(`/organizations/${externalKey}`, { displayName });
};

export const getSpecifiedRelationships = (uuid) => async dispatch => {
  try {
    dispatch(getSpecifiedRelationshipsPending());
    const organization = await ajax.get(`/organizations/${uuid}?attachRelationships=true`);

    dispatch(getSpecifiedRelationshipsSuccess());

    return organization?.data;

  } catch (error) {
    console.log('error: ', error);
    dispatch(getSpecifiedRelationshipsRejected(error));

    return error;
  }
};

export const updateOrganizationRelationships = (ultimateObject, addMessage) => async dispatch => {
  try {
    dispatch(updateOrganizationRelationshipsPending());

    let {
      newData,
      oldOrgData,
      relData: relTypes,
      currentOrgData
    } = ultimateObject;

    let newRelationshipTypeND = relTypes.new.value;

    if (!newRelationshipTypeND) {
      newRelationshipTypeND = '';
    }

    if (currentOrgData.newChildOrgName) {
      try {
        dispatch(updateOrganizationNamePending());

        await updateOrganizationName(currentOrgData);

        dispatch(updateOrganizationNameSuccess());

      } catch (error) {
        console.log('error: ', error);
        dispatch(updateOrganizationNameRejected(error));
      }
    }

    // **************************************************************************************
    /* We don't have access to all the relationships at once because the orgs page really
     * renders org-relationship_type pairs, (not the full org) so we need to build the new
     * state object in two parts.
     * 1. The portion that is not available to edit on rendered Wizard because they are of
     *    a separate relationship_type.
     * 2. The portion that is editable on the rendered Wizard */
    const originalRelationshipState = oldOrgData?.map(r => {
      if (r.partyType === 'ORGANIZATION') {
        return {
          toPartyKey: r.hierarchy === 'parent' ? currentOrgData.childOrgKey : r.externalKey,
          fromPartyKey: r.hierarchy === 'parent' ? r.externalKey : currentOrgData.childOrgKey,
          relationshipType: r.relationshipType
        };
      } else if (r.partyType === 'PERSON') {
        return {
          fromPartyKey: currentOrgData.childOrgKey,
          toPartyKey: r.externalKey,
          relationshipType: r.relationshipType
        };
      } else {
        throw new Error('oldOrgData includes a partyType that is not either PERSON or ORGANIZATION');
      }
    });

    const newRelationshipState = []; // what we will use in our state update body

    /* 1. First we need to add everything that's not visible on the current page
     * to the newRelationshipState obj. */
    originalRelationshipState.forEach(r => {
      /* if the relationship type doesn't match the relType.original (which
       * is simply the rel type shown on the edit page when we first render)
       * then we know it can't be edited on this page and that it will remain
       * in the new relationship state.*/
      if (r.relationshipType !== relTypes.original.value) {
        newRelationshipState.push(r); // These are the untouched relationships
      }
    });

    /* 2. Then we need to add all the new relationships from the current Wizard  */
    newData?.forEach(r => {
      newRelationshipState.push({
        toPartyKey: currentOrgData.childOrgKey,
        fromPartyKey: r.value,
        relationshipType: relTypes.new.value
      });
    });

    dispatch(updateOrganizationRelationshipsPending());
    await ajax.put(`/organizations/${currentOrgData.childOrgKey}/relationships`, { relationships: newRelationshipState });

    dispatch(updateOrganizationRelationshipsSuccess());
    dispatch(applyOrgFilter(null));
    addMessage({ children: `${currentOrgData.newChildOrgName || currentOrgData.childOrgName} has been edited`, action: 'dismiss' });

    await dispatch(getOrganizations());

  } catch (error) {
    dispatch(errorDialogToggle({ error, visible: true }));
    dispatch(applyOrgFilter(null));
    dispatch(updateOrganizationRelationshipsRejected(error));

    return error.message;
  }
};
