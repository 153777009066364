import { some, isEmpty } from 'lodash';
import { getPermissions, getAuthUser, isGlobalAdmin } from '.';

export function getFeatureAccess() {
  const perm = getPermissions();
  const authUser = getAuthUser();
  const permissions = perm?.permissions;
  const roles = authUser?.g?.map(o => o?.r);
  const isOperator = roles?.includes('OPERATOR');
  const isViewer = roles?.includes('VIEWER');
  const isGhost = !!perm?.gk || (!!authUser?.gk && isGlobalAdmin);

  function checkAppAccess(path, scope) {
    return some(permissions, { path, scope });
  }

  function createAccess() {
    const isFullAccess = checkAppAccess('suite', 'WRITE');

    if (isFullAccess) {
      return {
        ghosting: true,
        vips: true,
        live: true,
        analytics: true,
        devices: true,
        api: true,
        portal: true,
        education: true,
        scheduler: true,
        prepaid_pass_manager: true,
        provisioning: true,
        users: true,
        signal: true,
        network_diagram: true
      };

    } else {
      const paths = permissions
        ?.map(permission => ({ [permission?.path?.split('suite.')[1]]: true }))
        ?.reduce((acc, v) => {
          return { ...acc, ...v };
        }, {});

      if (paths) {
        paths.ghosting = isGhost;
      }

      return paths;
    }
  }

  // Temporary solution until RBAC in implemented across all environments
  function createTempAccess() {
    return {
      ghosting: isGhost,
      vips: isGlobalAdmin || isViewer || isOperator,
      live: isGlobalAdmin || isViewer || isOperator,
      analytics: isGlobalAdmin || isViewer || isOperator,
      devices: isGlobalAdmin || isViewer || isOperator,
      api: true,
      portal: isGlobalAdmin || isViewer || isOperator,
      education: true,
      scheduler: isGlobalAdmin,
      prepaid_pass_manager: isGlobalAdmin,
      provisioning: isGlobalAdmin,
      users: isGlobalAdmin,
      signal: isGlobalAdmin,
      network_diagram: isGlobalAdmin
    };
  }

  if (isEmpty(perm?.permissions)) {
    return createTempAccess();

  } else {
    return createAccess();
  }
};

export const checkPermissions = (permissionName) => {
  const access = getFeatureAccess();

  if (isEmpty(access)) {
    return false;

  } else {
    return access?.[permissionName] || false;
  }
};
